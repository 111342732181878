import React from "react";
import MainMagazines from "./components/magazines/MainMagazines";
import MainNewComplexes from "./components/new-complexes/NewComplexes";
import MainTopBuilders from "./components/top-builders/MainTopBuilders";
import MainTop from "./components/top/MainTop";
import MainUsefulLinks from "./components/useful-links/MainUsefulLinks";
import SeoText from "./components/seo-text/SeoText";
import HotOffers from "./components/hot-offers/HotOffers";
import styles from "./Index.module.scss";
import { cx } from "utils/cx";
import { BannerImageType, BannerPosition, IBanner, IBannerImage } from "pages/app/utils/models/banner";
import { Pagination } from "utils/models/pagination";
import { NewResidentalComplexModel } from "utils/models/residentialComplexModel";
import CustomFAQ from "common/custom-faq";
import LatestAddedComplexes from "./components/latest-added-complexes";
import ReadyComplexes from "./components/ready-complexes";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import { useBanners } from "pages/app/services/queries";
import ViewAdv from "common/view-adv/ViewAdv";
import CustomImage from "common/custom-image";
import defaultRightBannerUz from '../../assets/images/ad-right-uz-2.webp';
import defaultRightBannerRu from '../../assets/images/ad-right-ru-2.webp';
import cls from './components/new-complexes/NewComplexes.module.scss';
import ShimmerImage from "common/shimmer-image";
import { UZ } from "utils/constants/locales";
import MainReels from "./components/main-reels/main-reels";
type Props = {
  banner: Partial<Record<BannerPosition, IBanner<Record<BannerImageType, IBannerImage>>>>;
  topComplexes: Pagination<NewResidentalComplexModel>;
  latestAddedComplexes: Pagination<NewResidentalComplexModel>;
  readyComplexes: NewResidentalComplexModel[];
};
const Index: React.FC<Props> = ({
  banner,
  topComplexes,
  latestAddedComplexes,
  readyComplexes
}) => {
  const tMainTop = useTranslations("body.mainTop");
  const {
    locale
  } = useRouter();
  const {
    data: banners,
    isFetching
  } = useBanners('main', locale!);
  return <div className="relative" data-sentry-component="Index" data-sentry-source-file="Index.tsx">
      <MainTop banner={banner} data-sentry-element="MainTop" data-sentry-source-file="Index.tsx" />
      <div className={cx("flex justify-center gap-6  w-full", styles.box)}>
        <HotOffers data-sentry-element="HotOffers" data-sentry-source-file="Index.tsx" />
        <div>
          <MainNewComplexes banner={banner} topComplexes={topComplexes} data-sentry-element="MainNewComplexes" data-sentry-source-file="Index.tsx" />

          <LatestAddedComplexes latestAddedComplexes={latestAddedComplexes} data-sentry-element="LatestAddedComplexes" data-sentry-source-file="Index.tsx" />
          {readyComplexes && readyComplexes?.length > 0 && <ReadyComplexes readyComplexes={readyComplexes} />}
          <MainUsefulLinks data-sentry-element="MainUsefulLinks" data-sentry-source-file="Index.tsx" />
          <MainReels data-sentry-element="MainReels" data-sentry-source-file="Index.tsx" />
          <MainTopBuilders data-sentry-element="MainTopBuilders" data-sentry-source-file="Index.tsx" />
          <MainMagazines data-sentry-element="MainMagazines" data-sentry-source-file="Index.tsx" />
          <SeoText data-sentry-element="SeoText" data-sentry-source-file="Index.tsx" />
          <div className="flex flex-col gap-6 max-w-[1230px] my-0 mx-auto xl:px-0 px-4 mt-5">
            <CustomFAQ type="home" data-sentry-element="CustomFAQ" data-sentry-source-file="Index.tsx" />
          </div>
        </div>
        <div className="desctop_hot_offers">
          {isFetching ? <div className={cx("w-[296px] rounded-xl md:h-[600px] bg-gray-200 animate-pulse sticky top-[100px] mt-[47px]", "desctop_hot_offers")} /> : <>
              {banners?.right_side?.images?.web?.url ? <ViewAdv uuid={banners?.right_side?.id}>
                  <a className={cls.reklama_right} target={banners?.right_side?.is_blank ? '_blank' : '_self'} href={`${process.env.NEXT_PUBLIC_BASE_URL_NEW}/advertisement/view?uuid=${banners?.right_side?.uuid}&lang=${locale}`}>
                    <div className={cls.reklama_tag}>
                      <span>{tMainTop('Реклама')}</span>
                    </div>
                    <div className={cls.image_container}>
                      <CustomImage src={banners?.right_side?.images?.web?.url} alt="banner image" title="banner image" />
                    </div>
                  </a>
                </ViewAdv> : <div className={cx("w-[296px]  md:h-[600px] sticky top-[100px] mt-[47px]", "desctop_hot_offers")}>
                <ShimmerImage className="w-full h-full rounded-xl" src={locale === UZ ? defaultRightBannerUz : defaultRightBannerRu} alt="right-banner-image" />
              </div>}
            </>}
        </div>
      </div>
    </div>;
};
export default Index;
import React from 'react';
import { useRouter } from 'next/router';
import { useGetMainReels } from 'pages/main/services/queries';
import { layoutLang } from 'utils/language/layout';
import { Grid } from 'antd';
import CustomLink from 'common/custom-link';
import MainReelsCard from 'common/main-reels-card/main-reels-card';
import ReelsModal from 'common/reels-modal/reels-modal';
const MainReels = () => {
  const {
    locale
  } = useRouter();
  const {
    data
  } = useGetMainReels();
  const {
    md
  } = Grid.useBreakpoint();
  return <div className='wrapper mt-5 px-4 md:px-0 mx-auto' data-sentry-component="MainReels" data-sentry-source-file="main-reels.tsx">
      <div className='flex flex-col gap-4 px-3 md:px-5 py-4 md:py-6 bg-white rounded-xl'>
        <div className='flex items-center justify-between'>
          <p className='text-[#2C2C2C] text-xl md:text-2xl font-semibold'>UYSOT Reels</p>
          <CustomLink className='text-[#00A389] text-base md:text-xl font-medium' href={'/reels'} data-sentry-element="CustomLink" data-sentry-source-file="main-reels.tsx">{layoutLang[locale || 'ru']['Hammasi']}</CustomLink>
        </div>
        <div className='grid grid-cols-2 gap-2 md:gap-[18px] md:grid-cols-3 lg:grid-cols-5'>
          {(!md ? data?.slice(0, 4) : data)?.map(item => <div key={item?.id} className='h-[250px] md:h-[342px]'>
              <MainReelsCard item={item} />
            </div>)}
        </div>
      </div>

      <ReelsModal data-sentry-element="ReelsModal" data-sentry-source-file="main-reels.tsx" />
    </div>;
};
export default MainReels;
export const seoText = {
  uz: `
    Bizning kompaniyamiz O‘zbekistonda turar-joy ob’ektlarini sotish bo‘yicha ixtisoslashgan. 
    Saytdagi katalogda turli shaharlardagi yangi turar-joy majmualarida joylashgan kvartiralar bo‘yicha takliflarni topishingiz mumkin. 
    Biz ishonchli quruvchilar bilan hamkorlik qilamiz, 
    shunda sizga zamonaviy yechimlarni taklif etamiz — shinam studiyalardan tortib, ta’mirlangan hashamatli duplekslargacha. <br /><br /> 

    <strong>Aksiyalar va qulay shartlar:</strong> <br />
    Saytimizdagi aksiyalarni kuzatib boring — turli toifadagi uy-joy majmualarida kvartira sotib olish bo‘yicha foydali takliflar muntazam yangilanib boradi. 
    Hamkor-banklar tomonidan taqdim etiladigan ipoteka va hujjatlarni rasmiylashtirish bo‘yicha yordam xarid jarayonini yanada osonlashtiradi. <br /><br /> 

    <strong>Qulay qidiruv va qo‘llab-quvvatlash:</strong> <br />
    Kompaniyamiz mutaxassislari sizning talablaringizga mos variantlarni saralashga yordam beradi — xoh oilaviy yashash uchun tinch hududda, 
    xoh shahar markazida bo‘lsin. 
    Sayt orqali ariza qoldiring yoki menejerimizga qo‘ng‘iroq qiling, shunda sizga maslahat beramiz. <br /><br /> 

    <strong>Kafolat va xavfsizlik:</strong> <br />
    Biz mijozlarimiz huquqlarini himoya qilamiz: barcha bitimlar tekshiruvdan o‘tadi, mulk haqidagi ma’lumotlar esa sinchkovlik bilan tahlil qilinadi. 
    Yuridik jihatlar, jumladan, kadastrda ro‘yxatdan o‘tkazish, kompaniyamiz xizmatlariga kiradi. 
    Sizning ma’lumotlaringizning xavfsizligi bizning ustuvor vazifamizdir. <br /><br /> 

    <strong>Ijaradan charchadingizmi?</strong> O‘z uy-joyingizga ega bo‘lish orzusini ro‘yobga chiqarish vaqti keldi! 
    <a style="color: #1570EF" href="https://uysot.uz" target="_blank" rel="noopener noreferrer">Uysot.uz</a> platformasida 
    O‘zbekiston bo‘ylab eng maqbul, sifatli va ishonchli yangi qurilgan uylarni topishingiz mumkin. 
    Bizning katalogimizda turli xil zamonaviy kvartiralar va uylarga ega bo‘lasiz — ta’mirsiz xonadonlardan tortib, 
    to‘liq ta’mirlangan yashash joylarigacha. <br /><br />

    Biz eng dolzarb takliflarni taqdim etamiz, shu jumladan foizsiz bo‘lib-bo‘lib to‘lash, kredit va ipoteka imkoniyatlari mavjud, 
    shunda har bir inson uy-joy sotib olishni haqiqatga aylantira oladi. 
    Texnik xususiyatlar va rejalashtirish variantlari tufayli siz orzuingizdagi uyni oson tanlashingiz mumkin. 
    Ma’lumotlarimiz muntazam yangilanib turadi, shuning uchun siz doimo eng so‘nggi narxlar va takliflardan xabardor bo‘lasiz. <br /><br /> 

    <a style="color: #1570EF" href="https://uysot.uz" target="_blank" rel="noopener noreferrer">Uysot.uz</a> — 
    o‘z uy-joyingizga ega bo‘lish yo‘lidagi ishonchli hamkoringiz. 
    Ijarani unuting, bugunoq qulay narxlarda yangi uy tanlang!
  `,

  ru: `
    Наша компания специализируется на продаже жилых объектов в Узбекистане. 
    В каталоге на сайте вы найдете предложения по квартирам в новых комплексах различных городов. 
    Мы сотрудничаем с проверенными застройщиками, чтобы предложить вам современные решения — 
    от уютных студий до элитных дуплексов с ремонтом. <br /><br /> 

    <strong>Акции и выгодные условия:</strong> <br />
    Следите за акциями на нашем сайте — выгодные предложения по покупке квартир в комплексах различных классов регулярно обновляются. 
    Ипотека от партнеров-банков и помощь в оформлении документов сделают процесс покупки еще проще. <br /><br /> 

    <strong>Удобный поиск и поддержка:</strong> <br />
    Специалисты нашей компании помогут сортировать варианты под ваши запросы — будь то семейное жилье в отдаленном спокойном районе или прямо в центре. 
    Оставьте заявку на сайте или свяжитесь с менеджером, чтобы получить консультацию. <br /><br /> 

    <strong>Гарантии и безопасность:</strong> <br />
    Мы защищаем права клиентов: все сделки проходят проверку, а информация о недвижимости тщательно анализируется. 
    Работа с юридическими аспектами, включая регистрацию в кадастре, входит в услуги нашей компании. 
    Безопасность ваших данных — наш приоритет. <br /><br /> 

    <strong>Устали от аренды?</strong> Настало время воплотить мечту о собственном жилье с нашей платформой! 
    На <a style="color: #1570EF" href="https://uysot.uz" target="_blank" rel="noopener noreferrer">Uysot.uz</a> 
    вы найдете самые доступные, качественные и надежные новостройки по всему Узбекистану. 
    В нашем каталоге представлены современные квартиры и дома на любой вкус — 
    от квартир без ремонта до полностью отремонтированных жилых помещений. <br /><br />

    Мы предлагаем самые актуальные предложения с возможностью беспроцентной рассрочки, кредита и ипотеки, 
    чтобы покупка жилья стала реальностью для каждого. 
    Благодаря техническим характеристикам и планировкам вы сможете легко выбрать дом своей мечты. 
    Наши данные регулярно обновляются, поэтому вы всегда будете в курсе последних цен и предложений. <br /><br /> 

    <a style="color: #1570EF" href="https://uysot.uz" target="_blank" rel="noopener noreferrer">Uysot.uz</a> — 
    ваш надежный партнер на пути к приобретению собственного жилья. 
    Забудьте об аренде, выберите новый дом по доступной цене уже сегодня!
  `
};

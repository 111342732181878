import { useGetHotFilters } from "pages/main/services/queries";
import React from "react";
import { sub_titles } from "./HotOffers";
import { filterPriceSeparator } from "utils/helpers/filterPriceSeparator";
import { useRouter } from "next/router";
import { rootPaths } from "utils/constants/rootPaths";
import { layoutLang } from "utils/language/layout";
import { RU, USD } from "utils/constants/locales";
import { calcUsdValue } from "utils/helpers/calcUsdValue";
import { useAppSelector } from "hooks/reduxHooks";
import HotOffersLocation from "./HotOffersLocation";
import EmptyOfferIcon from "assets/icons/EmptyOfferIcon";
import { isEmptyArr } from "utils/helpers/isEmptyArr";
import { Spin } from "antd";
import CustomLink from "common/custom-link";
import { DEFAULT_LOCATION } from "utils/constants/defaultLocation";
const HotOffersMobile = () => {
  const {
    locale,
    query
  } = useRouter();
  const currency = useAppSelector(state => state.localeReducer?.currency);
  const usdValue = useAppSelector(state => state.localeReducer?.usdValue);
  const hotOffersRegionValue = useAppSelector(state => state.favouritesReducer?.hotOffersRegionValue);
  const regionId = hotOffersRegionValue?.city ? hotOffersRegionValue?.city?.id : hotOffersRegionValue?.district?.city?.id;
  const districtId = hotOffersRegionValue?.district && hotOffersRegionValue?.district?.id;
  const {
    data,
    isLoading
  } = useGetHotFilters(regionId, districtId!);
  const newData = data?.map(item => ({
    ...item,
    sub_title: locale === RU ? sub_titles[item?.sort_key as keyof typeof sub_titles]?.ru : sub_titles[item?.sort_key as keyof typeof sub_titles]?.uz
  }));
  const path_Name = regionId && districtId ? `/${hotOffersRegionValue?.city?.url || hotOffersRegionValue?.district?.city?.url}${rootPaths.RESIDENTIAL_COMPLEXES}` : regionId ? `/${hotOffersRegionValue?.city?.url || hotOffersRegionValue?.district?.city?.url}${rootPaths.RESIDENTIAL_COMPLEXES}` : districtId ? `/${hotOffersRegionValue?.district?.city?.url}${rootPaths.RESIDENTIAL_COMPLEXES}` : `/${DEFAULT_LOCATION}${rootPaths.RESIDENTIAL_COMPLEXES}`;
  const path_query = (sort_key: string, sort_value: string) => {
    if (regionId && districtId) {
      return {
        sort_value: sort_value,
        sort_key: sort_key,
        city_id: regionId,
        district_id: districtId
      };
    } else if (regionId) {
      return {
        sort_value: sort_value,
        sort_key: sort_key,
        city_id: regionId
      };
    } else if (districtId) {
      return {
        sort_value: sort_value,
        sort_key: sort_key,
        district_id: districtId
      };
    } else {
      return {
        sort_value: sort_value,
        sort_key: sort_key
      };
    }
  };
  return <div className="flex flex-col gap-3 pb-8" data-sentry-component="HotOffersMobile" data-sentry-source-file="HotOffersMobile.tsx">
      <div className="flex flex-col gap-1">
        <span className="text-[#2C2C2C] text-lg font-semibold leading-[22px]">
          {layoutLang[locale || 'ru']["qaynoq_takliflar"]}
        </span>
        <HotOffersLocation data-sentry-element="HotOffersLocation" data-sentry-source-file="HotOffersMobile.tsx" />
      </div>
      <Spin spinning={isLoading} data-sentry-element="Spin" data-sentry-source-file="HotOffersMobile.tsx">
        {!isEmptyArr(newData) ? <div className="grid grid-cols-2 gap-2">
            {newData?.map((item, index) => <div key={index} className="relative">
                <CustomLink href={{
            pathname: path_Name,
            query: query?.currency ? {
              ...path_query(item?.sort_key, item?.sort_value),
              currency: query?.currency
            } : path_query(item?.sort_key, item?.sort_value)
          } as never} key={index} className="static">
                  <span className="absolute w-full h-full top-0 left-0 z-[1] block"></span>
                </CustomLink>
                  <div className="flex flex-col gap-1 p-4 bg-[#F9FAFB] border border-solid border-[#F2F4F7] rounded-xl cursor-pointer w-full" style={{
            boxShadow: "0px 0.5px 0.63px 0px #89898909, 0px 4px 5px 0px #89898912"
          }}>
                    <div className="relative w-max">
                      <span className="text-[#1D2939] text-[17px] font-medium">
                        {item?.sort_key === "delay" || item?.sort_key === "discount_percent" ? item?.sort_key === "delay" ? `${item?.value} ${layoutLang[locale || 'ru']["oy"]}` : `${item?.value} %` : `${currency === USD ? `${Math.ceil(calcUsdValue(item?.value, usdValue, currency))?.toLocaleString(RU)} $` : `${filterPriceSeparator(item?.value, locale)} ${layoutLang[locale || 'ru']["sum"]}`}`}
                      </span>
                      {item?.percent && <div className="absolute right-[-36px] top-[-3px]">
                          <span className={`text-[13px] font-semibold ${item?.percent > 0 ? "text-[#F04438]" : "text-[#12B76A]"}`}>
                            {`${item?.percent > 0 ? "+" : ""} ${item?.percent}%`}
                          </span>
                        </div>}
                    </div>
                    <span className="text-[#00A389] text-[13px] font-medium">
                      {item?.sub_title}
                    </span>
                  </div>
              </div>)}
          </div> : <div className="flex flex-col items-center gap-3.5 mt-6">
            <EmptyOfferIcon />
            <span className="text-[#344054] text-base font-semibold">
              {layoutLang[locale || 'ru']["malumot topilmadi"]}
            </span>
          </div>}
      </Spin>
    </div>;
};
export default HotOffersMobile;
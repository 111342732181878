import React from 'react';
import { useRouter } from 'next/router';
import { rootPaths } from "../../../../utils/constants/rootPaths";
// import { urls } from "utils/constants/urls"
import { BannerImageType, BannerPosition, IBanner, IBannerImage } from "pages/app/utils/models/banner";
import { Pagination } from "utils/models/pagination";
import { NewResidentalComplexModel } from "utils/models/residentialComplexModel";
import { cx } from "utils/cx";
import { layoutLang } from "utils/language/layout";
// import { useBanners } from "pages/app/services/queries"
import { DEFAULT_LOCATION } from 'utils/constants/defaultLocation';
import ComplexCard from "../../../../common/common-card/complex/ComplexCard";
// import ViewAdv from "../../../../common/view-adv/ViewAdv"
import HotOffersMobile from "../hot-offers/HotOffersMobile";
import CustomLink from "common/custom-link";
import MobileNewComplexes from "./mobile-new-complexes";
import PaginationNextIcon from "assets/icons/PaginationNextIcon";
// import CustomImage from "common/custom-image"
import RegionItem from 'common/filter-items/region-item/region-item';
import styles from "./NewComplexes.module.scss";
type Props = {
  banner: Partial<Record<BannerPosition, IBanner<Record<BannerImageType, IBannerImage>>>>;
  topComplexes: Pagination<NewResidentalComplexModel>;
};
const MainNewComplexes: React.FC<Props> = ({
  topComplexes: data
}) => {
  // const t = useTranslations("body.newComplexes")
  // const tMainTop = useTranslations("body.mainTop")
  const {
    locale
  } = useRouter();

  // const { data: banners } = useBanners('main', locale!)

  return <section className={styles.new_buildings} data-sentry-component="MainNewComplexes" data-sentry-source-file="NewComplexes.tsx">
      <div className="wrapper">
        <div className="mobile_hot_offers">
          <HotOffersMobile data-sentry-element="HotOffersMobile" data-sentry-source-file="NewComplexes.tsx" />
          <RegionItem data-sentry-element="RegionItem" data-sentry-source-file="NewComplexes.tsx" />
        </div>
        <div className="flex items-center justify-between">
          <h2 className={styles.title}>{layoutLang[locale || 'ru']['mashxur_binolar']}</h2>
          <div className="mobile_only">
            <CustomLink href={`/${DEFAULT_LOCATION}${rootPaths.RESIDENTIAL_COMPLEXES}`} data-sentry-element="CustomLink" data-sentry-source-file="NewComplexes.tsx">
              <span className={styles.all_link}>
                {layoutLang[locale || 'ru']['Hammasi']} <PaginationNextIcon data-sentry-element="PaginationNextIcon" data-sentry-source-file="NewComplexes.tsx" />
              </span>
            </CustomLink>
          </div>
        </div>
        <div className={cx(styles.body, 'desctop_only')}>
          
          {data?.data?.map(item => <ComplexCard data={item} key={item?.id} />)}
        </div>
        <div className="mobile_only">
          <MobileNewComplexes data={data?.data} type="new_complexes" data-sentry-element="MobileNewComplexes" data-sentry-source-file="NewComplexes.tsx" />
        </div>
        <div className={cx(styles.bottom, 'desctop_only')}>
          <CustomLink href={`/${DEFAULT_LOCATION}${rootPaths.RESIDENTIAL_COMPLEXES}`} data-sentry-element="CustomLink" data-sentry-source-file="NewComplexes.tsx">{layoutLang[locale || 'ru']['barchasi']}</CustomLink>
        </div>
      </div>
    </section>;
};
export default MainNewComplexes;
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import { BannerImageType, IBanner, IBannerImage } from "pages/app/utils/models/banner";
import React from "react";
import styles from "./banner.module.scss";
import CustomImage from "common/custom-image";
interface IProps {
  banner?: IBanner<Record<BannerImageType, IBannerImage>> | undefined;
}
const Banner: React.FC<IProps> = ({
  banner
}) => {
  const t = useTranslations("body.mainTop");
  const {
    locale
  } = useRouter();
  return <div data-sentry-component="Banner" data-sentry-source-file="Banner.tsx">
      {(banner?.title[locale as keyof typeof banner.title] || banner?.subtitle[locale as keyof typeof banner.subtitle]) && <div className="relative">
            <a className="static" target={banner?.is_blank ? "_blank" : "_self"} href={`${process.env.NEXT_PUBLIC_BASE_URL_NEW}/advertisement/view?uuid=${banner?.uuid}&lang=${locale}`}>
              <span className="absolute w-full h-full top-0 left-0 z-[1] block"></span>
            </a>
              <div className={`flex w-full flex-col items-start gap-4 rounded-2xl bg-[#FFFFFF4D] p-3 sm:w-max sm:flex-row sm:px-4 sm:py-3 ${styles.bannerMain}`} style={{
        backdropFilter: "blur(20px)"
      }}>
                <div className={`flex w-max items-center justify-center overflow-hidden rounded-xl text-base font-medium text-white ${styles.bannerLogo}`}>
                  {banner?.logo &&
          // eslint-disable-next-line @next/next/no-img-element
          <CustomImage src={banner?.logo} alt="banner logo" className="w-12 h-12  object-contain" title="banner logo"
          // loading="lazy"
          />}
                </div>
                <div className="flex w-full flex-col gap-1.5 sm:w-max">
                  <div className="flex items-center justify-between gap-20">
                    <p className="text-base font-semibold text-white">{banner?.title[locale as keyof typeof banner.title]}</p>
                    <div className="rounded-[15px] bg-[#FFFFFFB2] px-1.5 py-1 text-xs text-[#2C2C2C]">{t("Реклама")}</div>
                  </div>
                  <p className="font-medium text-white">{banner?.subtitle[locale as keyof typeof banner.subtitle]}</p>
                </div>
              </div>
          </div>}
    </div>;
};
export default Banner;
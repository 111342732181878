import React from "react";
import { useRouter } from "next/router";
import { layoutLang } from "utils/language/layout";
import { cx } from "utils/cx";
import { seoText } from "./seo-static-text";
import ArrowBottom from "assets/icons/ArrowBottom";
import styles from './seoText.module.scss';
const SeoText = () => {
  const {
    locale
  } = useRouter();
  return <section className="flex flex-col gap-5 md:gap-6 max-w-[1230px] my-0 mx-auto xl:px-0 px-4" data-sentry-component="SeoText" data-sentry-source-file="SeoText.tsx">
      <p className="text-[#2c2c2c] text-[18px] md:text-2xl font-semibold">
        {layoutLang[locale || 'ru']["seo_title"]}
      </p>
      <details className={`${styles.details} p-4 md:p-6 bg-white rounded-3xl`}>
        <summary className="flex flex-col gap-3">
          <div className={cx("leading-[20.8px] text-[#475467] text-base font-medium", styles.about_complex_text)} dangerouslySetInnerHTML={{
          __html: seoText[locale as keyof typeof seoText]
        }} />
          <div className={cx("flex items-center gap-2 text-sm font-normal text-[#086ADA] cursor-pointer", styles.details_all)}>
            {layoutLang[locale || 'ru']['Batafsil']}
            <ArrowBottom className={styles.arrow_icon} stroke="#086ADA" data-sentry-element="ArrowBottom" data-sentry-source-file="SeoText.tsx" />
          </div>
        </summary>
      </details>
    </section>;
};
export default SeoText;
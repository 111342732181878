import React from "react";
import { useRouter } from "next/router";
import { layoutLang } from "utils/language/layout";
import ShimmerImage from "common/shimmer-image";
import builderQuiz from '../../../../assets/images/builder-quiz-img.webp';
import EmptyFrame from "assets/icons/empty-frame";
import CustomLink from "common/custom-link";
const BuildersQuiz = () => {
  const {
    locale
  } = useRouter();
  return <div className="px-4 xl:px-0 mt-5" data-sentry-component="BuildersQuiz" data-sentry-source-file="BuildersQuiz.tsx">
      <div className="w-full h-[180px] md:h-[240px] rounded-2xl overflow-hidden relative">
        <div className="absolute w-full h-full top-0 left-0 z-[2]" style={{
        background: 'linear-gradient(90deg, #004D40 0%, rgba(1, 179, 150, 0) 100%)'
      }} />
        <div className="relative z-10 h-full w-full md:w-[60%] p-6 md:p-9 flex flex-col justify-between">
          <span className="text-white font-bold text-xl leading-[26px] md:text-[36px] md:leading-[46.8px]">{layoutLang[locale || 'ru']['builder_content'].toUpperCase()}</span>
          <CustomLink href={"https://t.me/uysot_admin"} className="h-[41px] md:h-[43px] !text-white text-sm md:text-base px-5 rounded-lg w-max flex items-center justify-center font-medium cursor-pointer" style={{
          background: 'linear-gradient(88.14deg, #0DC683 0.98%, #00A389 99.81%)'
        }} data-sentry-element="CustomLink" data-sentry-source-file="BuildersQuiz.tsx">
            {layoutLang[locale || 'ru']['ariza_yuborish']}
          </CustomLink>
        </div>
        <div className='absolute z-[1] right-0 top-0 h-[275px] md:h-full'>
          <ShimmerImage alt='building-img' title='building-img' src={builderQuiz} className='h-[180px] md:h-[240px] object-cover' data-sentry-element="ShimmerImage" data-sentry-source-file="BuildersQuiz.tsx" />
          <div className='absolute top-0 right-0 hidden md:block'>
            <EmptyFrame data-sentry-element="EmptyFrame" data-sentry-source-file="BuildersQuiz.tsx" />
          </div>
        </div>
        <div className="bg-[#47474766] h-7 md:h-[43px] px-5 flex items-center justify-center w-max text-xs font-medium text-white absolute z-20 right-0 bottom-0 rounded-tl-2xl" style={{
        backdropFilter: 'blur(16px)'
      }}>
          {layoutLang[locale || 'ru']['for_builders']}
        </div>
      </div>
    </div>;
};
export default BuildersQuiz;
import { useLocale, useTranslations } from "next-intl";
import React from "react";
import { ROOMS_COUNT, DEADLINE_YEARS, LOCATION } from "../../../../utils/constants/queryParamsKeys";
import { rootPaths } from "../../../../utils/constants/rootPaths";
import { useGetApartmentCounts } from "../../services/queries";
import styles from "./MainUsefulLinks.module.scss";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import CustomLink from "common/custom-link";
import { cx } from "utils/cx";
import useful1 from '../../../../assets/images/useful1.webp';
import useful2 from '../../../../assets/images/useful2.webp';
import ShimmerImage from "common/shimmer-image";
import { DEFAULT_LOCATION } from "utils/constants/defaultLocation";
import { RU } from "utils/constants/locales";
const MainUsefulLinks: React.FC = () => {
  const t = useTranslations("body.usefulLinks");
  const {
    query
  } = useRouter();
  const locale = useLocale();
  const {
    data: apartmentCounts
  } = useGetApartmentCounts();
  const deadlineEndYear = dayjs().endOf("year").format("YYYY");
  const deadlineNextYear = dayjs().add(1, "year").endOf("year").format("YYYY");
  const deadlineNextTwoYear = dayjs().add(2, "year").endOf("year").format("YYYY");

  // location
  const location = query[LOCATION] ? query[LOCATION] : DEFAULT_LOCATION;
  const deadlineFilters = [{
    id: 2,
    deadlineYears: deadlineEndYear,
    item: t("Сдача до конца года")
  }, {
    id: 3,
    deadlineYears: deadlineNextYear,
    item: locale === RU ? t("Сдача до 2023") + dayjs().add(1, "year").format("YYYY") : dayjs().add(1, "year").format("YYYY") + t("Сдача до 2023")
  }, {
    id: 4,
    deadlineYears: deadlineNextTwoYear,
    item: locale === RU ? t("Сдача до 2024") + dayjs().add(2, "year").format("YYYY") : dayjs().add(2, "year").format("YYYY") + t("Сдача до 2024")
  }];
  return <section className={styles.container} data-sentry-component="MainUsefulLinks" data-sentry-source-file="MainUsefulLinks.tsx">
      <div className="wrapper">
        <h2 className={styles.container_title}>{t("Полезные ссылки")}</h2>
        <div className={`${styles.container_body} flex-wrap`}>
          <div className={styles.apartments}>
            <h3 className={styles.title}>{t("Купить квартиру")}</h3>
            <div className={styles.body}>
              {apartmentCounts?.map((item, index) => <CustomLink key={index} href={{
              pathname: `/${location}${rootPaths.APARTMENTS}`,
              query: {
                [ROOMS_COUNT]: item.rooms_count
              }
            } as never}>
                  <div className="flex justify-between items-center w-full">
                    <p>
                      {item?.rooms_count}-{t("комнатная")}
                    </p>
                    <span>{item?.apartments_count}</span>
                  </div>
                </CustomLink>)}
            </div>
          </div>
          <div className={styles.complexes}>
            <h3 className={styles.title}>{t("Новостройка")}</h3>
            <div className={styles.body}>
              <CustomLink href={{
              pathname: `/${location}${rootPaths.RESIDENTIAL_COMPLEXES}`,
              query: {
                completed_complex: true
              }
            } as never} data-sentry-element="CustomLink" data-sentry-source-file="MainUsefulLinks.tsx">
                <span>{t("Готовые ЖК")}</span>
              </CustomLink>
              {deadlineFilters?.map(item => <CustomLink href={{
              pathname: `/${location}${rootPaths.RESIDENTIAL_COMPLEXES}`,
              query: {
                [DEADLINE_YEARS]: item?.deadlineYears
              }
            } as never} key={item?.id}>
                  <span>{item?.item}</span>
                </CustomLink>)}
            </div>
            <div className={styles.image}>
              <ShimmerImage alt="useful-img" src={useful1} title="useful-img" className="!object-contain" data-sentry-element="ShimmerImage" data-sentry-source-file="MainUsefulLinks.tsx" />
            </div>
          </div>
          <div className={styles.calculator}>
            <div className={styles.left}>
              <h3 className={styles.title}>{t("Калькулятор ипотеки")}</h3>
              <p>
                {t("Рассчитайте платежи")} <br /> {t("и отправьте заявку")}
              </p>
              <CustomLink href={rootPaths.MORTGAGE_CALCULATOR} data-sentry-element="CustomLink" data-sentry-source-file="MainUsefulLinks.tsx">
                <span>{t("Рассчитать ипотеку")}</span>
              </CustomLink>
            </div>
            <div className={cx(styles.right, 'rounded-[20px] overflow-hidden')}>
              <ShimmerImage alt="useful-img" src={useful2} title="useful-img" className="!object-contain" data-sentry-element="ShimmerImage" data-sentry-source-file="MainUsefulLinks.tsx" />
            </div>
          </div>
        </div>
      </div>
    </section>;
};
export default MainUsefulLinks;
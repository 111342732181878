import React from "react";
import { rootPaths } from "../../utils/constants/rootPaths";
import { makeUrl } from "../../utils/helpers/makeUrl";
import { OneBuilderModel } from "pages/builders/utils/models/oneBuilderModel";
import CustomLink from "common/custom-link";
import styles from "./builderCard.module.scss";
import CustomImage from "common/custom-image";
const BuilderCard: React.FC<OneBuilderModel> = props => {
  return <CustomLink href={`${rootPaths.BUILDERS}/${makeUrl(props?.slug || props?.slug)}`} className={styles.builder_card} data-sentry-element="CustomLink" data-sentry-component="BuilderCard" data-sentry-source-file="BuilderCard.tsx">
      <div className={styles.builder_card_left}>
        <h3 className="!mb-0">{props.name}</h3>
      </div>
      <div className={styles.builder_card_right}>
        {props?.logo && <CustomImage src={props.logo} alt={props.name} className="object-contain" title={props.name} />}
      </div>
    </CustomLink>;
};
export default BuilderCard;